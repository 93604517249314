import {baseUrl, PageParam, TableDataType} from "../types/res/cpe";
import {request} from "../../utils";
import {OptionRes, PageRes} from "../types/common";
import {PaginationProps} from "antd";
import commonApi from "../commonApi";

const cpeApi = {
    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (body) {
            if (body.cpeName) {
                url += 'cpeName=' + body.cpeName + "&";
            }
            if (body.sdWanId) {
                url += 'sdWanId=' + body.sdWanId + "&";
            }
            if (body.cpeDomain) {
                url += 'cpeDomain=' + body.cpeDomain + "&";
            }
            if (body.userName) {
                url += 'userName=' + body.userName + "&";
            }
            if (body.enableStatus) {
                url += 'enableStatus=' + body.enableStatus + "&";
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(body:any) {
        let url = baseUrl + "/delete";
        return request.post(url, body);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl, body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/' + 'update';
        return request.put(url, body)
    },
    //查询下拉列表数据
    options(sdWanId : number){
        let url=baseUrl + "/options";
        url += "?";
        url += "sdWanId=" + sdWanId;
        return request.get<OptionRes[]>(url)
    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"cpe配置列表",body)
    },

    //启用
    enable(body: any) {
        let url = baseUrl + "/enable";
        return request.post(url, body)
    },

    //禁用
    disable(body: any) {
        let url = baseUrl + "/disable";
        return request.post(url, body)
    },

}

export default cpeApi



