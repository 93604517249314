
const baseUrl = "/res/instance";
/** 获取产品申请列表-参数 */
export interface PageParam  {
    idcId:number
    statusCd:string
    subscriberCode:string
    phoneNumber:string
    serverCodeStr:string
    hostCodeStr:string
    instanceCodeStr:string
    impartStatus:string
    activateMark:string
    onlineMark:string
    handleMark:string
    faultMark:string
    enableMark:string
    faultTypeCd:string
    instanceGradeCd:string
    instanceSn:number
    instanceIp:string
    tagName:string
    beginSubsTime:string
    endSubsTime:string
    beginControlledTime:string
    endControlledTime:string
    beginExpTime:string
    endExpTime:string
    startHostIp:string
    endHostIp:string
    rootStatus:string
    rootStatusDesc:string
    notes:string
    createTime:string
    modifyTime:string
    poolId:number
}

//定义表格的属性
export interface TableDataType {
    id: number;
    instanceCode: string;
    subscriberCode:string,
    phoneNumber:string
    idcName: string;
    serverModelName: string;
    serverCode: string;
    hostCode: string;
    instanceGradeDesc: string;
    instanceMac: string;
    instanceIp: string;
    osTypeDesc: string;
    osVersion: string;
    cpuQuota: number;
    gpuQuota: number;
    ramQuota: number;
    romQuota: number;
    imgVersion: string;
    launcherVersion: string;
    marketVersion: string;
    instancePoolDesc: string;
    tenantId: number;
    activateMark: string;
    activateMarkDesc: string;
    onlineMark: string;
    onlineMarkDesc: string;
    handleMark: string;
    handleMarkDesc: string;
    faultMark: string;
    faultMarkDesc: string;
    enableMark: string;
    enableMarkDesc: string;
    rootStatus: string;
    controlledTime: string;
    subsTime: string;
    expTime: string;
    maintainMark: string;
    maintainMarkDesc: string;
    impartStatusDesc: string;
    gatewayName: string;
    tcpPort: string;
    udpPort: string;
    faultTypeDesc: number;
    statusDesc: string;
    notes: string;
    poolName: string;
}





export  {baseUrl};

