import React, {useEffect, useState} from 'react';
import {Button, Form, Input, InputNumber, Modal, Row, Select} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {OptionRes} from "../../../../apis/types/common";
import TextArea from "antd/es/input/TextArea";
import {TableDataType} from "../../../../apis/types/res/sdwan";
import sdwanApi from "../../../../apis/res/sdwan";
import dayjs from "dayjs";
import {filterOption} from "../../../../apis/common";

// moment，并设置本地化语言为中文
moment.locale('zh-cn');


const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface StaffFromProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    open: boolean;
    type: boolean;
    model: String;
    idcIdOption: OptionRes[];
    proxyTypeCdOption: OptionRes[];
    proxyProtocolOption: OptionRes[];
}


const SdwanForm: React.FC<StaffFromProps> = ({
                                                 type,
                                                 refresh,
                                                 record,
                                                 open,
                                                 closeOpen,
                                                 model,
                                                 idcIdOption, proxyTypeCdOption, proxyProtocolOption
                                             }) => {
    const [form] = Form.useForm();

    // 编辑的时候回显数据
    useEffect(() => {
        // 在组件挂载时，设置表单数据为record的值
        form.setFieldsValue({})
        form.resetFields()
        if (open && record && !type) {
            form.setFieldsValue({
                ...record,
                enableStatus: record.enableStatus !== null && record.enableStatus !== undefined
                    ? record.enableStatus.toString() : null,
            });
        }
    }, [open]);

    //处理新增方法
    const handleCreate = () => {
        let values = form.getFieldsValue();
        values = {
            ...values,
            createTime: values.createTime ? dayjs(values.createTime).format('YYYY-MM-DD HH:mm:ss') : null,
            modifyTime: values.modifyTime ? dayjs(values.modifyTime).format('YYYY-MM-DD HH:mm:ss') : null,
        };
        sdwanApi.create(values).then((result) => {
            if (result) {
                refresh();
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields();
        closeOpen()
    };

    //处理更新方法
    function handleUpdate() {
        const values = form.getFieldsValue();
        let valueJSOn = JSON.stringify(values);
        sdwanApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
            if (result) {
                refresh();
            }
        });

    }


    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            if (values.id == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }

    return (
        <Modal
            title={model == 'create' ? "新增项目" : "编辑项目"}
            open={open}
            width={400}
            cancelText={"取消"}
            onCancel={handleCancel}
            footer={null}
        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={handleOK}
            >

                <Form.Item name="id" label="id值" hidden={true}>
                </Form.Item>
                <Form.Item name="sdwanName" label="sdwan名称"
                           rules={[{required: true, message: "sdwan名称不能为空"},
                               {max: 100, message: 'sdwan名称长度不能大于100'}]}>
                    <Input style={{width: 192}}/>
                </Form.Item>
                <Form.Item name="idcId" label="关联机房" rules={[{required: true, message: "关联机房必填"}]}>
                    <Select
                        style={{width: 175}}
                        showSearch
                        optionFilterProp="children"
                        allowClear

                        filterOption={filterOption}
                        options={idcIdOption}
                    />
                </Form.Item>
                <Form.Item name="sdwanDomain" label="控制台连接地址"
                           rules={[{required: true, message: "控制台连接地址不能为空"},
                               {max: 100, message: '控制台连接地址长度不能大于100'}]}>
                    <Input style={{width: 192}}/>
                </Form.Item>
                <Form.Item name="port" label="控制台连接端口">
                    <InputNumber style={{maxWidth: 192}} max={32767}/>
                </Form.Item>
                <Form.Item name="userName" label="连接帐号"
                           rules={[{required: true, message: "连接帐号不能为空"},
                               {max: 100, message: '连接帐号长度不能大于100'}]}>
                    <Input style={{width: 192}}/>
                </Form.Item>
                <Form.Item name="sdwanPassword" label="连接密码"
                           rules={[{required: true, message: "连接密码不能为空"},
                               {max: 100, message: '连接密码长度不能大于100'}]}>
                    <Input style={{width: 192}}/>
                </Form.Item>
                <Form.Item name="enableStatus" label="是否启用"
                           rules={[{required: true, message: "是否启用不能为空"}]}>
                    <Select
                        showSearch
                        allowClear={true}
                        style={{width: 192}}
                        options={[
                            {label: '启用', value: '1'},
                            {label: '禁用', value: '0'}
                        ]}
                    />
                </Form.Item>
                <Form.Item name="notes" label="备注" rules={[{max: 50, message: "备注长度不能超过50位"}]}>
                    <TextArea style={{width: 192}} rows={1}/>
                </Form.Item>
                <div style={{textAlign: "center"}}>
                    <Form.Item style={{display: "inline-block"}}>
                        <Button type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default SdwanForm;



