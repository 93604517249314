import {baseUrl, PageParam, TableDataType} from "../types/res/sdwan";
import {request} from "../../utils";
import {OptionRes, PageRes} from "../types/common";
import {PaginationProps} from "antd";
import commonApi from "../commonApi";

const sdwanApi = {
    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (body) {
            if (body.sdwanName) {
                url += 'sdwanName=' + body.sdwanName + "&";
            }
            if (body.idcId) {
                url += 'idcId=' + body.idcId + "&";
            }
            if (body.sdwanDomain) {
                url += 'sdwanDomain=' + body.sdwanDomain + "&";
            }
            if (body.userName) {
                url += 'userName=' + body.userName + "&";
            }
            if (body.enableStatus) {
                url += 'enableStatus=' + body.enableStatus + "&";
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(body:any) {
        let url = baseUrl + "/delete";
        return request.post(url, body);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl, body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/' + 'update';
        return request.put(url, body)
    },
    //查询下拉列表数据
    options(){
        let url=baseUrl;
        url= url + "/options" ;
        return request.get<OptionRes[]>(url)
    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"sdwan配置列表",body)
    },

    //启用
    enable(body: any) {
        let url = baseUrl + "/enable";
        return request.post(url, body)
    },

    //禁用
    disable(body: any) {
        let url = baseUrl + "/disable";
        return request.post(url, body)
    },

}

export default sdwanApi



