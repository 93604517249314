const baseUrl = "/res/cpe";
/** 获取产品申请列表-参数 */
export interface PageParam  {
    cpeName:string;
    sdWanId:number;
    cpeDomain:string;
    userName:string;
    enableStatus:number;
}

//定义表格的属性
export interface TableDataType {
    id: number;
    enableStatus: number;
}



export  {baseUrl};