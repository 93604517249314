import React, {useEffect, useState} from "react";
import {
    Button, Card,
    ConfigProvider,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber, message,
    Modal,
    Pagination,
    PaginationProps, Popconfirm,
    Select,
    Table,
    Typography

} from 'antd';
import {ColumnsType} from "antd/es/table";
import InstanceForm, {InstanceUpdateForm} from "./instanceForm";
import InsInitForm from "./insInitForm";
import {OptionRes} from "../../../../apis/types/common";
import propertiesApi from "../../../../apis/sys/properties";
import serviceApi from "../../../../apis/res/server";
import hostApi from "../../../../apis/res/host";
import {TableDataType} from "../../../../apis/types/res/instance";
import instanceApi from "../../../../apis/res/instance";
import gatewayApi from "../../../../apis/res/gateway";
import hostVirtualApi from "../../../../apis/res/hostVirtual";
import {filterOption} from "../../../../apis/common";
import idcApi from "../../../../apis/res/idc";
import dayjs from "dayjs";
import {ipRegex} from "../../../common/const";
import Details from "../../public/details/details";
import {Image} from 'antd';
import JSZip from 'jszip';
import {saveAs} from 'file-saver';
import InsGrantForm from "./InsGrantForm";
import TextArea from "antd/es/input/TextArea";
import insPoolApi from "../../../../apis/res/insPool";
import sdwanApi from "../../../../apis/res/sdwan";
import cpeApi from "../../../../apis/res/cpe";


const {RangePicker} = DatePicker;

const layout = {
    labelCol: {span: 6},
    wrapperCol: {span: 18},
};

const InstanceContent = () => {
    //进入页面先查询数据
    useEffect(() => {
        handleQuery();
        propertiesApi.optinos("DM_Instance_Status").then((res) => {
            if (res) {

                setStatusOption(res.data)
            }
        })

        propertiesApi.optinos("DM_Faoult_Type").then((res) => {
            if (res) {
                setFaultTypeOption(res.data)
            }
        })
        propertiesApi.optinos("DM_Impart_Status").then((res) => {
            if (res) {

                setImpartStatusOption(res.data)
            }
        })
        propertiesApi.optinos("DM_Instance_Pool").then((res) => {
            if (res) {
                setInstancePoolOption(res.data)
            }
        })
        propertiesApi.optinos("DM_Instance_Grade").then((res) => {
            if (res) {
                setGradeOption(res.data)
            }
        })
        serviceApi.optinos().then((res) => {
            if (res) {
                setIdcOption(res.data)
            }
        })
        hostApi.optinos().then((res) => {
            if (res) {
                setHostOption(res.data)
            }
        })
        gatewayApi.optinos().then((res) => {
            if (res) {
                setGatewayOption(res.data)
            }
        })
        hostVirtualApi.optinos().then((res) => {
            if (res) {
                setVirtualOption(res.data)
            }
        })
        idcApi.optinos().then((res) => {
            if (res) {
                setIdcOption(res.data)
            }
        })

        propertiesApi.optinos('DM_AddTime_Unit').then((res) => {
            if (res) {
                setTimeUnitOption(res.data)
            }
        })

        insPoolApi.optinos().then((res) => {
            if (res) {
                setInsPoolNameOption(res.data)
            }
        })
        sdwanApi.options().then((res) => {
            if (res) {
                setSdwanNameOption(res.data)
            }
        })
        insPoolApi.optinos().then((res) => {
            if (res) {
                setInsPoolNameOption(res.data)
            }
        })

        sdwanApi.options().then((res) => {
            if (res) {
                setSdwanNameOption(res.data)
            }
        })

    }, []);
    //定义用到的useState，用来绑定数据
    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState<TableDataType[]>([]);
    const [idList, setIdList] = useState<Number[]>([]);
    const [open, setOpen] = useState(false)
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [type, setType] = useState(false)
    const [openUpdate, setOpenUpdate] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [idcOption, setIdcOption] = useState<OptionRes[]>([])
    const [timeUnitOption, setTimeUnitOption] = useState<OptionRes[]>([])
    const [greadeOption, setGradeOption] = useState<OptionRes[]>([])
    const [hostOption, setHostOption] = useState<OptionRes[]>([])
    const [gatewayOption, setGatewayOption] = useState<OptionRes[]>([])
    const [virtualOption, setVirtualOption] = useState<OptionRes[]>([])
    const [instancePoolOption, setInstancePoolOption] = useState<OptionRes[]>([])
    const [faultTypeOption, setFaultTypeOption] = useState<OptionRes[]>([])
    const [impartStatusOption, setImpartStatusOption] = useState<OptionRes[]>([])
    const [statusOption, setStatusOption] = useState<OptionRes[]>([])
    const [initOpen, setInitOpen] = useState(false)
    const [insPoolNameOption, setInsPoolNameOption] = useState<OptionRes[]>([])
    const [sdwanNameOption, setSdwanNameOption] = useState<OptionRes[]>([])
    const [cpeOption, setCpeOption] = useState<OptionRes[]>([])


    const [total, setTotal] = useState<number>()
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })

    const [openCheckIds, setOpenCheckIds] = useState(false);
    const [openRebootInsCheck, setRebootInsCheck] = useState(false);
    const [openResetInsCheck, setResetInsCheck] = useState(false);
    const [openInitInsCheck, setInitInsCheck] = useState(false);
    const [openUnBindCheck, setOpenUnBindCheck] = useState(false);
    const [openEnable, setOpenEnable] = useState(false);
    const [openDisable, setOpendisable] = useState(false);
    const [openBindForm, setOpenBindForm] = useState(false);
    const [openMovePool, setOpenMovePool] = useState(false);
    const [openSetIp, setOpenSetIp] = useState(false);
    const [openAddTimeForm, setOpenAddTimeForm] = useState(false);
    const [openReshelveForm, setOpenReshelveForm] = useState(false);
    const [openGrantForm, setOpenGrantForm] = useState(false);
    const [openScreenshotCheck, setOpenScreenshotCheck] = useState(false);
    const [openScreenshotImage, setOpenScreenshotImage] = useState(false);
    const [screenshotUrls, setScreenshotUrls] = useState<string[]>([]);
    const [screenshotData, setScreenshotData] = useState([]);
    const [selectedRowsData, setSelectedRowsData] = useState<TableDataType[]>([]);
    const [screenshotLoading, setScreenshotLoading] = useState<boolean>(true);
    //定义操作类型：1-重启云手机，2-一键新机 3-绑定 4-解绑，5-加时长 6-回收的云手机重新上架,7-截屏，8-启用,9-禁用，10-恢复出厂设置，11-变更云手机池
    const [operate, setOperate] = useState<Number>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [key, setKey] = useState(0);

    useEffect(() => {
        if (cpeOption.length === 0) {
            form.setFieldsValue({ cpeId: null });
            setKey(prevKey => prevKey + 1); // 更新键值以触发重新渲染
        }
    }, [cpeOption]);

    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();
        console.log("body", body)
        body = {
            ...body,
            beginSubsTime: body.beginSubsTime ? dayjs(body.beginSubsTime).format('YYYY-MM-DD') : null,
            endSubsTime: body.endSubsTime ? dayjs(body.endSubsTime).format('YYYY-MM-DD') : null,
            beginControlledTime: body.beginControlledTime ? dayjs(body.beginControlledTime).format('YYYY-MM-DD') : null,
            endControlledTime: body.endControlledTime ? dayjs(body.endControlledTime).format('YYYY-MM-DD') : null,
            beginExpTime: body.beginExpTime ? dayjs(body.beginExpTime).format('YYYY-MM-DD') : null,
            endExpTime: body.endExpTime ? dayjs(body.endExpTime).format('YYYY-MM-DD') : null,
        };
        console.log("queryBody is", body)
        let res = instanceApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
                setSelectedRowKeys([]);
                setIdList([]);

            }
        })

    }

    // 处理 sdWanId 变化
    const handleSdWanIdChange = (value:any) => {
        setIpForm.setFieldsValue({ cpeId: undefined })
        setCpeOption([])
        if(value == undefined){
            return;
        }
        cpeApi.options(value).then((res) => {
            if (res) {
                setCpeOption(res.data);
            }
        });
    };

    // 在组件加载时设置初始值并触发 onChange 事件
    useEffect(() => {
        setIpForm.setFieldsValue({ sdWanId:null});
        handleSdWanIdChange(' ');
    }, [openSetIp]);

// 导出全部图片的函数
    const exportAllImages = () => {
        const zip = new JSZip();
        const promises = [];
        console.log("screenshotData:" + screenshotData)
        // 遍历所有图片 URL，并将其添加到压缩包中
        screenshotData.forEach((item, index) => {
            // 使用 fetch 获取图片文件
            promises.push(fetch(item.url)
                .then(response => response.blob())
                .then(blob => {
                    // 提取图片名称
                    const imageName = selectedRowsData.find(a => a.id === item.insId)?.instanceCode;
                    // 将图片文件添加到压缩包中，提取图片的后缀
                    const urlParts = item.url.split('/');
                    const fileName = urlParts[urlParts.length - 1]; // 获取文件名部分
                    const fileExtension = fileName.split('.').pop(); // 获取文件后缀
                    // 将图片文件添加到压缩包中
                    zip.file(`${imageName}.${fileExtension}`, blob);
                }));
        });

        // 等待所有图片文件都添加到压缩包中后，进行压缩并下载
        Promise.all(promises).then(() => {
            // 生成压缩包
            zip.generateAsync({type: 'blob'})
                .then(blob => {
                    // 下载压缩包
                    saveAs(blob, 'images.zip');
                });
        });
    };

    // 刷新截图url
    const refreshImages = () => {
        // 创建一个新的 screenshotData 副本
        const updatedScreenshotData = [...screenshotData];

        // 更新所有图片的 URL
        updatedScreenshotData.forEach(item => {
            // 在图片的 URL 后面添加一个随机参数来避免缓存
            item.url = `${item.url}?${new Date().getTime()}`;
        });

        // 使用更新后的数据更新 screenshotData 状态
        setScreenshotData(updatedScreenshotData);
    };

    //处理删除
    const handleDelete = () => {
        instanceApi.batchDelete(idList).then((result) => {
            if (result) {
                //接口成功后重置idlist
                setIdList([])
                //重新调用一次查询接口
                handleQuery();
            }
        });

    }

    const [messageApi, contextHolder] = message.useMessage();
    const handleAppDerive = () => {
        let body = form.getFieldsValue();
        body = {
            ...body,
            beginSubsTime: body.beginSubsTime ? dayjs(body.beginSubsTime).format('YYYY-MM-DD') : null,
            endSubsTime: body.endSubsTime ? dayjs(body.endSubsTime).format('YYYY-MM-DD') : null,
            beginControlledTime: body.beginControlledTime ? dayjs(body.beginControlledTime).format('YYYY-MM-DD') : null,
            endControlledTime: body.endControlledTime ? dayjs(body.endControlledTime).format('YYYY-MM-DD') : null,
            beginExpTime: body.beginExpTime ? dayjs(body.beginExpTime).format('YYYY-MM-DD') : null,
            endExpTime: body.endExpTime ? dayjs(body.endExpTime).format('YYYY-MM-DD') : null,
        };
        instanceApi.derive(body);
    }
    function confirmDerive() {
        handleAppDerive();
    }

    function cancelDerive() {
        messageApi.open({
            type: 'error',
            content: '取消',
            className: 'custom-class',
            style: {
                margin: "auto",
                top: "40vh",
                position: "relative"
            },
        });
    }

    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        {
            title: '操作',
            dataIndex: 'operation',
            render: (_: any, record: TableDataType) => {
                return <Typography.Link onClick={() => {
                    setOpenUpdate(true)
                    setRowData(record);
                    setType(false)
                }}>
                    编辑
                </Typography.Link>
            },

        },
        {
            title: '云手机编码',
            dataIndex: 'instanceCode',
            key: 'instanceCode',
            width: 150,
            ellipsis: true,

        },
        {
            title: '会员编码',
            dataIndex: 'subscriberCode',
            key: 'subscriberCode',
            ellipsis: true

        },
        {
            title: '电话号码',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            ellipsis: true,

        },
        {
            title: '用户云手机',
            dataIndex: 'tagName',
            key: 'tagName',
            ellipsis: true,


        },

        {
            title: '所在机房',
            dataIndex: 'idcName',
            key: 'idcName',
            ellipsis: true,


        },
        {
            title: '服务器型号',
            dataIndex: 'serverModelName',
            key: 'serverModelName',
            ellipsis: true,


        },

        {
            title: '服务器编码',
            dataIndex: 'serverCode',
            key: 'serverCode',
            ellipsis: true,


        },
        {
            title: '宿主机',
            dataIndex: 'hostCode',
            key: 'hostCode',
            width: 150,
            ellipsis: true,


        },
        {
            width: 150,

            title: '管理节点名称',
            dataIndex: 'manageNodeIdDesc',
            key: 'manageNodeIdDesc',
            ellipsis: true,

        },
        {
            width: 100,
            title: '云手机池',
            dataIndex: 'poolName',
            key: 'poolName',
            ellipsis: true,

        },
        {
            title: '云手机等级',
            dataIndex: 'instanceGradeDesc',
            key: 'instanceGradeDesc',

            ellipsis: true,

        }, {
            title: '云手机SN',
            dataIndex: 'instanceSn',
            key: 'instanceSn',
            ellipsis: true,


        }, {
            title: 'MAC地址',
            dataIndex: 'instanceMac',
            key: 'instanceMac',
            ellipsis: true

        }, {
            title: 'IP地址',
            dataIndex: 'instanceIp',
            key: 'instanceIp',

            ellipsis: true,

        },
        {
            title: 'OS类型',
            dataIndex: 'osTypeDesc',
            key: 'osTypeDesc',

            ellipsis: true,

        },
        {
            title: 'OS版本',
            dataIndex: 'osVersion',
            key: 'osVersion',
            ellipsis: true,


        },


        {
            title: '在线标识',
            dataIndex: 'onlineMarkDesc',
            key: 'onlineMarkDesc',
            ellipsis: true,


        },
        {
            title: '操控标识',
            dataIndex: 'handleMarkDesc',
            key: 'handleMarkDesc',
            ellipsis: true,


        },
        {
            title: '维护标识',
            dataIndex: 'maintainMarkDesc',
            key: 'maintainMarkDesc',

            ellipsis: true,

        },
        {
            title: '分配状态',
            dataIndex: 'impartStatusDesc',
            key: 'impartStatusDesc',
            ellipsis: true,


        }, {
            title: '启用标识',
            dataIndex: 'enableMarkDesc',
            key: 'enableMarkDesc',
            ellipsis: true,


        }, {
            title: '激活标识',
            dataIndex: 'activateMarkDesc',
            key: 'activateMarkDesc',
            ellipsis: true,


        },
        {
            title: 'root状态',
            dataIndex: 'rootStatusDesc',
            key: 'rootStatusDesc',
            ellipsis: true,


        },
        {
            title: '网关编号',
            dataIndex: 'gatewayName',
            key: 'gatewayName',

            ellipsis: true,

        },
        {
            title: '默认域名',
            dataIndex: 'gatewayDomain',
            key: 'gatewayDomain',
            ellipsis: true


        }, {
            title: '电信域名',
            dataIndex: 'ctccDomain',
            key: 'ctccDomain',
            ellipsis: true


        }, {
            title: '移动域名',
            dataIndex: 'cmccDomain',
            key: 'cmccDomain',
            ellipsis: true


        }
        , {
            title: '联通',
            dataIndex: 'cuccDomain',
            key: 'cuccDomain',
            ellipsis: true


        },
        {
            title: 'TCP端口',
            dataIndex: 'tcpPort',
            key: 'tcpPort',
            ellipsis: true


        },
        {
            title: 'UDP端口',
            dataIndex: 'udpPort',
            key: 'udpPort',
            ellipsis: true,


        },
        {
            title: '故障标识',
            dataIndex: 'faultMarkDesc',
            key: 'faultMarkDesc',
            ellipsis: true,


        }, {
            title: '故障类型',
            dataIndex: 'faultTypeDesc',
            key: 'faultTypeDesc',
            ellipsis: true,


        },
        {
            title: '受控时间',
            dataIndex: 'controlledTime',
            key: 'controlledTime',
            ellipsis: true,


        },
        {
            title: '到期时间',
            dataIndex: 'expTime',
            key: 'expTime',
            ellipsis: true,


        },
        {
            title: '订阅时间',
            dataIndex: 'subsTime',
            key: 'subsTime',
            ellipsis: true,


        },

        {
            title: '释放状态',
            dataIndex: 'releasedStatusDesc',
            key: 'releasedStatusDesc',
            ellipsis: true,
            width: 100
        },

        {
            width: 100,

            title: '截图proxy编码',
            dataIndex: 'screenProxyCode',
            key: 'screenProxyCode',
            ellipsis: true,

        },

        {
            title: '状态',
            dataIndex: 'statusDesc',
            key: 'statusDesc',
            ellipsis: true,
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            ellipsis: true,
        },
        {
            title: '修改时间',
            dataIndex: 'modifyTime',
            key: 'modifyTime',
            ellipsis: true,
        },
        {
            title:'sdwan设备',
            dataIndex: 'sdWanName',
            key: 'sdWanName',
            ellipsis: true,
        },
        {
            title: 'cpe设备',
            dataIndex: 'cpeName',
            key: 'cpeName',
            ellipsis: true,
        },
        {
            title: '备注',
            dataIndex: 'notes',
            key: 'notes',
            ellipsis: true,


        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            setSelectedRowsData(selectedRows)
            console.log("selectedRows11:" + selectedRows)
            const newSet = new Set(selectedRows.map((key) => key.id));
            //这里有个类型转换
            setIdList(Array.from(newSet) as Number[]);
            setSelectedRowKeys(selectedRowKeys);

        }
    };


    //获取要查询的表单
    const [form] = Form.useForm();
    const [bindForm] = Form.useForm();
    const [movePoolForm] = Form.useForm();
    const [setIpForm] = Form.useForm();
    const [addTimeForm] = Form.useForm();

    //处理操作类型对应的值：//定义操作类型：1-重启云手机，2-一键新机 3-绑定 4-解绑，5-加时长 6-回收的云手机重新上架，7-云手机截屏
    const handleOperate = () => {
        if (operate === 1) {
            // 处理为重启云手机的情况
            instanceApi.rebootIns(idList).then((res) => {
                if (res) {

                    setRebootInsCheck(false)
                    handleQuery();
                }
            })
        } else if (operate == 4) {
            //解绑
            instanceApi.unbind(idList).then((res) => {
                if (res) {
                    openMole(res.data);
                    setOpenUnBindCheck(false)
                    handleQuery();
                }
            })
        } else if (operate == 6) {
            // 回收的云手机重新上架
            instanceApi.reshelve(idList).then((res) => {
                if (res) {
                    setOpenReshelveForm(false)
                    handleQuery();
                }
            })
        } else if (operate == 7) {
            // 云手机截屏
            const body = {
                instanceIds: idList,
                // width: 720,
                // height: 1280,
            }
            instanceApi.screenshot(body).then((res) => {
                if (res.data) {
                    // 获取截图的URL列表
                    const screenshotUrls = res.data.map(item => item.url);

                    // // 弹出多图片预览
                    setOpenScreenshotCheck(false)
                    setOpenScreenshotImage(true); // 设置打开图片预览的状态为true，这将显示预览组件
                    setScreenshotUrls(screenshotUrls); // 将截图URL列表传递给预览组件
                    setScreenshotData(res.data)
                    setOpenScreenshotImage(true); // 设置打开图片预览的状态为true，这将显示预览组件
                    // 在加载完成后检查截图 URL 是否可访问
                    checkScreenshotUrls(screenshotUrls);
                }
            })
        } else if (operate === 8) {
            //启用
            instanceApi.enable(idList).then((res) => {
                if (res) {
                    openMole(res.data)
                    setOpenEnable(false)
                    handleQuery();
                }
            })
        } else if (operate === 9) {
            //禁用
            instanceApi.disable(idList).then((res) => {
                if (res) {
                    openMole(res.data)
                    setOpendisable(false)
                    handleQuery();
                }
            })
        }

    };

    const openMole=(dataList:any)=>{
        // 统计成功和失败的数量
        let successCount = 0;
        let failureCount = 0;
        let failureMessages = [];
        dataList.forEach((item) => {
            if (item.flag) {
                successCount++;
            } else {
                failureCount++;
                failureMessages.push('云手机'+item.instanceId+item.failMsg);
            }
        });

        if (failureMessages.length > 0) {
            const modal = Modal.error({
                title: '失败'+failureCount+'条,失败原因如下：',
                content: (
                    <div>
                        {failureMessages.map((msg, index) => (
                            <div key={index}>{msg}</div>
                        ))}
                    </div>
                ),
                centered: true,
                // 添加onCancel来处理用户点击关闭按钮的情况
                onCancel: () => {
                    console.log("Modal canceled!");
                    modal.destroy();
                },

            });

        }
    }

    // 设定定时器，检查截图的URL是否可访问
    const checkScreenshotUrls = (urls) => {
        let count = 10; // 检查次数
        const interval = setInterval(() => {
            // 检查是否所有URL都可访问
            // const allUrlsAccessible = urls.every(url => checkUrlAccessibility(url));
            const urlAccessible = checkUrlAccessibility(urls[0]);
            if (urlAccessible || count <= 0) {
                // 取消定时器
                clearInterval(interval);
                // 设置加载状态为 false，结束加载
                setScreenshotLoading(false);
            }
            count--;
        }, 300); // 每次检查间隔 500 毫秒
    };

    // 检查 URL 是否可访问的函数
    const checkUrlAccessibility = async (url) => {
        try {
            const response = await fetch(url, {method: 'HEAD'});
            return response.ok; // 如果响应状态码在 200-299 范围内，则返回 true，表示 URL 可访问
        } catch (error) {
            console.error('Error checking URL accessibility:', error);
            return false; // 如果发生错误，或者响应状态码不在 200-299 范围内，则返回 false，表示 URL 不可访问
        }
    };


    return (

        <div>

            <div>

                <ConfigProvider
                    theme={{
                        token: {
                            margin: 10
                        },
                        // components: {
                        //     Form: {
                        //         /* 这里是你的组件 token */
                        //         itemMarginBottom: 30,
                        //         labelHeight: 5,
                        //         labelFontSize: 11,
                        //
                        //     },
                        // },
                    }}
                >

                    {/*查询的表单*/}
                    <Form form={form} name="validateOnly" autoComplete="off"

                          onFinish={() => {
                              pagination.current = 1;
                              setCurrentPage(1);
                              handleQuery();
                          }}
                          labelAlign={"left"}
                          layout={"inline"}
                        // labelCol={{ span: 14 ,offset:14}}
                        // wrapperCol={{ span: 4 }}
                          size={"small"}
                          style={{maxWidth: 3000}}
                    >

                        <Form.Item style={{margin: 5}} name="subscriberCode" label="会员编码">
                            <Input></Input>
                        </Form.Item>

                        <Form.Item style={{margin: 5}} name="phoneNumber" label="手机号码">
                            <Input></Input>
                        </Form.Item>


                        <Form.Item style={{margin: 5}} name="serverCodeStr"
                                   label="服务器编码">
                            <Input></Input>
                        </Form.Item>


                        <Form.Item style={{margin: 5}} name="hostCodeStr"
                                   label="宿主机编码">
                            <Input></Input>
                        </Form.Item>

                        <Form.Item style={{margin: 5}} name="instanceCodeStr"
                                   label="云手机编码">
                            <Input></Input>
                        </Form.Item>


                        <Form.Item style={{margin: 5}} name="activateMark" label="激活标识">
                            <Select style={{width: 90}}
                                    showSearch
                                    className="statsItem"
                                    optionFilterProp="children"
                                    allowClear
                                    filterOption={filterOption}
                                    options={[
                                        {label: '未激活', value: '0'},
                                        {label: '已激活', value: '1'}
                                    ]}
                            />
                        </Form.Item>
                        <Form.Item style={{margin: 5}} name="onlineMark" label="在线标识">
                            <Select
                                style={{width: 90}}
                                showSearch
                                className="statsItem"
                                optionFilterProp="children"
                                allowClear
                                filterOption={filterOption}
                                options={[
                                    {label: '在线', value: '1'},
                                    {label: '离线', value: '0'}
                                ]}
                            />
                        </Form.Item>
                        <Form.Item style={{margin: 5}} name="handleMark" label="操控标识">
                            <Select
                                style={{width: 90}}
                                showSearch
                                optionFilterProp="children"
                                allowClear
                                filterOption={filterOption}
                                options={[
                                    {label: '已操控', value: '1'},
                                    {label: '未操控', value: '0'}
                                ]}
                            />
                        </Form.Item>

                        <Form.Item style={{margin: 5}} name="faultMark" label="故障标识">
                            <Select
                                style={{width: 90}}
                                showSearch
                                optionFilterProp="children"
                                allowClear
                                filterOption={filterOption}
                                options={[
                                    {label: '故障', value: '1'},
                                    {label: '正常', value: '0'}
                                ]}
                            />
                        </Form.Item>

                        <Form.Item style={{margin: 5}} name="poolId" label="云手机池">
                            <Select
                                style={{width: 150}}
                                showSearch
                                optionFilterProp="children"
                                allowClear
                                filterOption={filterOption}
                                options={insPoolNameOption}
                            />
                        </Form.Item>


                        <Form.Item style={{margin: 5}} name="enableMark" label="启用标识">
                            <Select
                                style={{width: 90}}
                                showSearch
                                optionFilterProp="children"
                                allowClear
                                filterOption={filterOption}
                                options={[
                                    {label: '启用', value: '1'},
                                    {label: '禁用', value: '0'}
                                ]}
                            />
                        </Form.Item>


                        <Form.Item style={{margin: 5}} name="faultTypeCd" label="故障类型">
                            <Select
                                style={{width: 90}}
                                showSearch
                                optionFilterProp="children"
                                allowClear
                                filterOption={filterOption}
                                options={faultTypeOption}
                            />
                        </Form.Item>


                        <Form.Item style={{margin: 5}} name="rootStatus" label="root状态">
                            <Select
                                style={{width: 90}}
                                showSearch
                                optionFilterProp="children"
                                allowClear
                                filterOption={filterOption}
                                options={[
                                    {label: '有root', value: '1'},
                                    {label: '无root', value: '0'}
                                ]}
                            />
                        </Form.Item>


                        <Form.Item style={{margin: 5}} name="impartStatus" label="分配状态">
                            <Select
                                style={{width: 90}}
                                showSearch
                                optionFilterProp="children"
                                allowClear
                                filterOption={filterOption}
                                options={impartStatusOption}></Select>
                        </Form.Item>


                        <Form.Item style={{margin: 5}} name="statusCd" label="状态">
                            <Select
                                style={{width: 90}}
                                showSearch
                                optionFilterProp="children"
                                allowClear
                                filterOption={filterOption}
                                options={statusOption}
                            />

                        </Form.Item>

                        <Form.Item style={{margin: 5}} name="instanceGradeCd" label="云手机等级">
                            <Select
                                style={{width: 90}}
                                showSearch
                                optionFilterProp="children"
                                allowClear
                                filterOption={filterOption}
                                options={greadeOption}
                            />
                        </Form.Item>


                        <Form.Item style={{margin: 5}} name="idcId" label="机房信息">
                            <Select
                                style={{width: 130}}
                                showSearch
                                optionFilterProp="children"
                                allowClear
                                filterOption={filterOption}
                                options={idcOption}
                            />
                        </Form.Item>


                        <Form.Item style={{margin: 5}} name="tagName" label="用户云手机">
                            <Input></Input>
                        </Form.Item>

                        <Form.Item style={{margin: 5}} name="instanceSn" label="云手机SN">
                            <InputNumber style={{width: 60}}></InputNumber>
                        </Form.Item>

                        <Form.Item style={{margin: 5}} name="subsTime" label="订阅时间">
                            <RangePicker
                                placeholder={['', '']}
                                onChange={(value) => {
                                    if (value == null) {
                                        form.setFieldValue("beginSubsTime", null);
                                        form.setFieldValue("endSubsTime", null);
                                    } else {
                                        if (Array.isArray(value)) {
                                            const valueArray = value;
                                            let date = Array.from(value);
                                            if (date.length == 2) {
                                                form.setFieldValue("beginSubsTime", date[0]);
                                                form.setFieldValue("endSubsTime", date[1]);
                                            }
                                            console.log('onOk: ', date);
                                        }
                                    }
                                }}></RangePicker>
                        </Form.Item>
                        <Form.Item style={{margin: 5}} name="beginSubsTime" hidden={true}></Form.Item>
                        <Form.Item style={{margin: 5}} name="endSubsTime" hidden={true}></Form.Item>
                        <Form.Item style={{margin: 5}} name="controlledTime" label="受控时间">
                            <RangePicker
                                placeholder={['', '']}

                                onChange={(value) => {
                                    if (value == null) {
                                        form.setFieldValue("beginControlledTime", null);
                                        form.setFieldValue("endControlledTime", null);
                                    } else if (Array.isArray(value)) {
                                        const valueArray = value;
                                        let date = Array.from(value);
                                        if (date.length == 2) {
                                            form.setFieldValue("beginControlledTime", date[0]);
                                            form.setFieldValue("endControlledTime", date[1]);
                                        }
                                        console.log('onOk: ', date);
                                    }
                                }}

                            />
                        </Form.Item>
                        <Form.Item style={{margin: 5}} name="beginControlledTime" hidden={true}></Form.Item>
                        <Form.Item style={{margin: 5}} name="endControlledTime" hidden={true}></Form.Item>
                        <Form.Item style={{margin: 5}} name="expTime" label="到期时间">
                            <RangePicker
                                placeholder={['', '']}

                                onChange={(value) => {
                                    if (value == null) {
                                        form.setFieldValue("beginExpTime", null);
                                        form.setFieldValue("endExpTime", null);
                                    } else if (Array.isArray(value)) {
                                        const valueArray = value;
                                        let date = Array.from(value);
                                        if (date.length == 2) {
                                            form.setFieldValue("beginExpTime", date[0]);
                                            form.setFieldValue("endExpTime", date[1]);
                                        }
                                        console.log('onOk: ', date);
                                    }
                                }}></RangePicker>

                        </Form.Item>
                        <Form.Item style={{margin: 5}} name="beginExpTime" hidden={true}></Form.Item>
                        <Form.Item style={{margin: 5}} name="endExpTime" hidden={true}></Form.Item>


                        <Form.Item style={{margin: 5}} name="instanceIp" label="云手机IP">
                            <Input
                            />

                        </Form.Item>


                        <Form.Item style={{margin: 5}} name="startHostIp" label="宿主机ip起" rules={[
                            {
                                pattern: ipRegex,
                                message: '请输入有效的IP地址',
                            },
                        ]}>
                            <Input
                            />

                        </Form.Item>


                        <Form.Item style={{margin: 5}} name="endHostIp" label="宿主机ip尾" rules={[
                            {
                                pattern: ipRegex,
                                message: '请输入有效的IP地址',
                            },
                        ]}>
                            <Input
                            />

                        </Form.Item>

                        <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

                        <div style={{display: 'flex', alignItems: 'center', padding: 0}}>
                            <Form.Item>


                                <Button type="primary" htmlType="submit">查询</Button>
                            </Form.Item>
                            <Form.Item>


                                <Button htmlType="reset" onClick={() => {
                                    const body = {};
                                    instanceApi.pageData(body, pagination).then((res) => {
                                        if (res) {
                                            setList(res.data.records)
                                            setTotal(res.data.total)
                                            setSelectedRowKeys([]);
                                            setIdList([]);

                                        }
                                    })
                                }}>重置</Button>
                            </Form.Item>
                            {/*<Form.Item>*/}


                            {/*    <Button type="primary" onClick={() => {*/}
                            {/*        setOpen(true)*/}
                            {/*        setType(true)*/}

                            {/*    }}>*/}
                            {/*        新增*/}
                            {/*    </Button>*/}
                            {/*</Form.Item>*/}
                            {/*<Popconfirm*/}
                            {/*    title="是否确认删除所选项？"*/}
                            {/*    onConfirm={() => {*/}
                            {/*        if (idList.length === 0) {*/}
                            {/*            message.error('删除失败，请选择需要删除的数据');*/}
                            {/*        } else {*/}
                            {/*            handleDelete();*/}
                            {/*        }*/}
                            {/*    }}*/}
                            {/*    onCancel={() => {*/}
                            {/*        message.error('取消删除');*/}
                            {/*    }}*/}
                            {/*    okText="是"*/}
                            {/*    cancelText="否"*/}
                            {/*>*/}
                            {/*    <Button type="primary" danger >*/}
                            {/*        删除*/}
                            {/*    </Button>*/}
                            {/*</Popconfirm>*/}
                            <Form.Item>


                                <Button type="primary" ghost onClick={() => {
                                    if (idList.length == 0) {
                                        setOpenCheckIds(true)
                                    } else {
                                        setOperate(1)
                                        setRebootInsCheck(true)
                                    }
                                }}>
                                    重启云手机
                                </Button>
                            </Form.Item>
                            <Form.Item>


                                <Button type="primary" ghost onClick={() => {
                                    if (idList.length == 0) {
                                        setOpenCheckIds(true)
                                    } else {
                                        setInitOpen(true);
                                        setOperate(2)
                                        setInitInsCheck(true)
                                    }
                                }}>
                                    一键新机
                                </Button>

                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" ghost onClick={() => {
                                    if (idList.length == 0) {
                                        setOpenCheckIds(true)
                                    } else {
                                        setOperate(7)
                                        setOpenScreenshotCheck(true)
                                    }
                                }}>
                                    截屏
                                </Button>
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" danger onClick={() => {
                                    if (idList.length == 0) {
                                        setOpenCheckIds(true)
                                    } else {
                                        setOperate(4)
                                        setOpenUnBindCheck(true)
                                    }
                                }}>
                                    解绑
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" onClick={() => {
                                    if (idList.length == 0) {
                                        setOpenCheckIds(true)
                                    } else {
                                        setOperate(3)
                                        setOpenBindForm(true)
                                    }
                                }}>
                                    绑定
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" onClick={() => {
                                    if (idList.length == 0) {
                                        setOpenCheckIds(true)
                                    } else {
                                        setOperate(11)
                                        setOpenMovePool(true)
                                    }
                                }}>
                                    变更云手机池
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" onClick={() => {
                                    if (idList.length == 0) {
                                        setOpenCheckIds(true)
                                    } else {
                                        setOperate(11)
                                        setOpenSetIp(true)
                                    }
                                }}>
                                    ip设备修改
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" ghost onClick={() => {
                                    if (idList.length == 0) {
                                        setOpenCheckIds(true)
                                    } else {
                                        setOperate(5)
                                        setOpenAddTimeForm(true)
                                    }
                                }}>
                                    加时长
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" ghost onClick={() => {
                                    if (idList.length == 0) {
                                        setOpenCheckIds(true)
                                    } else {
                                        setOperate(6)
                                        setOpenReshelveForm(true)
                                    }
                                }}>
                                    回收云手机重新上架
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" ghost onClick={() => {
                                    if (idList.length == 0) {
                                        setOpenCheckIds(true)
                                    } else if (idList.length > 1) {
                                        message.error('每次只能选择一条数据进行操作');
                                    } else {
                                        setOpenGrantForm(true)
                                    }
                                }}>
                                    管理员授权
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" ghost onClick={() => {
                                    if (idList.length == 0) {
                                        setOpenCheckIds(true)
                                    } else {
                                        setOperate(8)
                                        setOpenEnable(true)
                                    }
                                }}>
                                    启用
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" danger onClick={() => {
                                    if (idList.length == 0) {
                                        setOpenCheckIds(true)
                                    } else {
                                        setOperate(9)
                                        setOpendisable(true)
                                    }
                                }}>
                                    禁用
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Popconfirm
                                    title="是否确认导出筛选的数据？"
                                    onConfirm={confirmDerive}
                                    onCancel={cancelDerive}
                                    okText="是"
                                    cancelText="否"
                                >
                                    <Button type="primary" danger style={{marginRight: '25px'}}>
                                        导出
                                    </Button>
                                </Popconfirm>
                            </Form.Item>
                        </div>
                    </Form>
                </ConfigProvider>

            </div>


            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>


            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                console.log("details", record)
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: 3000, y: '53vh'}} rowSelection={{
                    ...rowSelection,"selectedRowKeys":selectedRowKeys,
                }} dataSource={list} columns={columns} rowKey={record => record.id} pagination={false}/>

            </ConfigProvider>

            {/*表格的分页*/
            }
            <div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>

                <Pagination size="small" style={{marginLeft: 'auto', float: 'right'}}
                            total={total}
                            showSizeChanger
                            showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>


            {/*新增的form表单，子组件*/
            }
            <InstanceForm
                refresh={() => {
                    //设置表单不展示
                    setOpen(false);
                    setRowData({} as TableDataType)
                    //重新调用查询的接口
                    handleQuery();
                }}
                record={rowData}
                open={open}
                closeOpen={() => {
                    setOpen(false)
                    setRowData({} as TableDataType)
                }}
                hostOption={hostOption}
                gatewayOption={gatewayOption}
                virtualOption={virtualOption}
                instancePoolOption={instancePoolOption}
                faultTypeOption={faultTypeOption}
                impartStatusOption={impartStatusOption}
                statusOption={statusOption}
                type={type}></InstanceForm>

            {/*编辑的form表单，子组件*/
            }
            <InstanceUpdateForm
                refresh={() => {
                    //设置表单不展示
                    setOpenUpdate(false);
                    setRowData({} as TableDataType)
                    //重新调用查询的接口
                    handleQuery();
                }}
                record={rowData}
                open={openUpdate}
                closeOpen={() => {
                    setOpenUpdate(false)
                    setRowData({} as TableDataType)
                }}
                faultTypeOption={faultTypeOption}
                impartStatusOption={impartStatusOption}
                statusOption={statusOption}
                type={type}
                gatewayOption={gatewayOption}
            ></InstanceUpdateForm>

            {/*判断操作的ids存在的弹窗*/
            }
            <Modal cancelText="取消"
                   okText="确认" title="" open={openCheckIds} onOk={() => {
                setOpenCheckIds(false)
            }} onCancel={() => {
                setOpenCheckIds(false)
            }}>
                先选择要操作的数据
            </Modal>
            {/*二次确认的弹窗*/
            }
            <Modal
                cancelText="取消"
                okText="确认"
                title="" open={openRebootInsCheck} onOk={() => {
                handleOperate();
            }} onCancel={() => {
                setOperate(0)
                setRebootInsCheck(false)
            }}>
                该操作会重启云手机，请确认是否继续。
            </Modal>

            <InsInitForm
                refresh={() => {
                    //设置表单不展示
                    setInitOpen(false);
                }}
                open={initOpen}
                closeOpen={() => {
                    setInitOpen(false)
                    handleQuery()
                }}
                initInsIdList={idList}
            ></InsInitForm>

            <Modal
                cancelText="取消"
                okText="确认"
                title="" open={openScreenshotCheck} onOk={() => {
                handleOperate();
                setSelectedRowKeys([])
                setIdList([])
            }} onCancel={() => {
                setOperate(0)
                setOpenScreenshotCheck(false)
            }}>
                该操作会截屏，请确认是否继续。
            </Modal>

            {/*截屏弹框*/}
            <Modal
                open={screenshotData.length > 0 && !screenshotLoading}
                footer={null}
                width={1000}
                style={{padding: '0 24px'}} // 设置 Modal 的左右内边距相等
                onCancel={() => {
                    setOperate(0)
                    setOpenScreenshotImage(false)
                    setScreenshotData([])
                }}
            >
                <Button type="primary" style={{marginRight: '8px'}} onClick={exportAllImages}>导出全部图片</Button>
                <Button onClick={refreshImages}>刷新</Button> {/* 添加刷新按钮 */}
                <Divider/>
                <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start'}}>
                    {openScreenshotImage && screenshotData.map((item, index) => {
                        return <div key={item.instanceId}
                                    style={{width: 'calc(20% - 16px)', marginBottom: '16px', marginRight: index % 5 !== 4 ? '16px' : '0'}}>
                            <div
                                style={{fontSize: '90%', marginBottom: '5px'}}>云手机编码：{selectedRowsData.find(a => a.id === item.instanceId)?.instanceCode}</div>
                            <Image key={item.instanceId} src={item.url}/> {/* 添加 onError 事件处理函数 */}
                        </div>
                    })}
                </div>

            </Modal>

            {/*二次确认的弹窗*/
            }
            <Modal
                cancelText="取消"
                okText="确认"
                title="" open={openUnBindCheck} onOk={() => {
                handleOperate();
            }} onCancel={() => {
                setOperate(0)
                setOpenUnBindCheck(false)
            }}>
                该操作用于解绑,请确认是否继续
            </Modal>
            {/*二次确认的弹窗*/
            }
            <Modal
                cancelText="取消"
                okText="确认"
                title="" open={openReshelveForm} onOk={() => {
                handleOperate();
            }} onCancel={() => {
                setOperate(0)
                setOpenReshelveForm(false)
            }}>
                该操作是用于将回收并检查通过的云手机重新放出，让用户可以购买订阅,请确认是否继续
            </Modal>

            {/*绑定的简单表单*/}

            <Modal
                cancelText="取消"
                okText="确认"
                title="" open={openBindForm} onOk={() => {
                let body = bindForm.getFieldsValue();
                body = {...body, idList: idList}
                instanceApi.bind(body).then((res) => {
                    if (res) {
                        openMole(res.data);
                        setOpenBindForm(false)
                        bindForm.resetFields();
                        handleQuery();
                    }
                })
            }} onCancel={() => {
                setOperate(3)
                bindForm.resetFields();
                setOpenBindForm(false)
            }}>
                <Form
                    form={bindForm}
                    name="control-hooks"
                    style={{maxWidth: 300}}
                >
                    <Form.Item name="phoneNumber" label="手机号码" rules={[{required: true, message: "手机号码必填"}]}>
                        <Input></Input>
                    </Form.Item>
                    <Form.Item name="days" label="时间(天）" rules={[{required: true, message: "时间必填"}]}>
                        <InputNumber></InputNumber>
                    </Form.Item>
                </Form>
            </Modal>

            {/*变更云手机池的简单表单*/}

            <Modal
                cancelText="取消"
                okText="确认"
                title="" open={openMovePool} onOk={() => {
                let body = movePoolForm.getFieldsValue();
                body = {...body, idList: idList}
                instanceApi.movePool(body).then((res) => {
                    if (res) {
                        setOpenMovePool(false)
                        movePoolForm.resetFields();
                        handleQuery();
                    }
                })
            }} onCancel={() => {
                setOperate(10)
                movePoolForm.resetFields();
                setOpenMovePool(false)
            }}>
                <Form
                    form={movePoolForm}
                    name="control-hooks"
                    style={{maxWidth: 300}}
                >
                    <Form.Item name="poolId" label="变更云手机池" rules={[{required: true, message: "变更云手机池必填"}]}>
                        <Select
                            style={{width: 150}}
                            showSearch
                            optionFilterProp="children"
                            allowClear
                            filterOption={filterOption}
                            options={insPoolNameOption}
                        />
                    </Form.Item>
                </Form>
            </Modal>

            {/*ip设备修改的简单表单*/}

            <Modal
                cancelText="取消"
                okText="确认"
                title="" open={openSetIp} onOk={() => {
                let body = setIpForm.getFieldsValue();
                body = {...body, idList: idList}
                instanceApi.setIpConf(body).then((res) => {
                    if (res) {
                        setOpenSetIp(false)
                        setIpForm.resetFields();
                        handleQuery();
                    }
                })
            }} onCancel={() => {
                setOperate(11)
                setIpForm.resetFields();
                setOpenSetIp(false)
            }}>
                <Form
                    form={setIpForm}
                    name="control-hooks"
                    style={{maxWidth: 300}}
                >
                    <Form.Item name="sdWanId" label="sdwan设备" help="选项为空时，解除sdwan设备绑定">
                        <Select
                            style={{width: 250}}
                            showSearch
                            optionFilterProp="children"
                            allowClear
                            filterOption={filterOption}
                            options={sdwanNameOption}
                            onChange={(value) => {
                                handleSdWanIdChange(value);
                            }}

                        />
                    </Form.Item>

                    <Form.Item name="cpeId" label="cpe设备" help="选项为空时，解除cpe设备绑定">
                        <Select
                            key={key}
                            style={{width: 250}}
                            showSearch
                            optionFilterProp="children"
                            allowClear
                            filterOption={filterOption}
                            options={cpeOption}
                            value={setIpForm.getFieldValue('cpeId')} // 绑定表单值
                        />
                    </Form.Item>

                </Form>
            </Modal>

            {/*加时长的简单表单*/}

            <Modal
                cancelText="取消"
                okText="确认"
                title=""
                open={openAddTimeForm} onOk={() =>{
                addTimeForm.validateFields().then(() => {
                    let body = addTimeForm.getFieldsValue();
                    body = {...body, idList: idList}
                    instanceApi.addTime(body).then((res) => {
                        if (res) {
                            setOpenAddTimeForm(false)
                            addTimeForm.resetFields();
                            handleQuery();
                        }
                    })
                }).catch((error) => {

                });
                }}
                onCancel={() => {
                setOperate(5)
                addTimeForm.resetFields();
                setOpenAddTimeForm(false)
            }}>
                <Form
                    {...layout}
                    form={addTimeForm}
                    name="control-hooks"
                    style={{maxWidth: 450}}
                >
                    <Form.Item name="addTime" label="时间" rules={[{required: true, message: "时间必填"}]}>
                        <InputNumber style={{width: 300}} ></InputNumber>
                    </Form.Item>
                    <Form.Item name="timeUnit" label="时间单位" rules={[{required: true, message: "时间单位必填"}]}>
                        <Select
                            showSearch
                            allowClear={true}
                            style={{width: 300}}
                            filterOption={filterOption}
                            options={timeUnitOption}
                        />
                    </Form.Item>
                    <Form.Item name="notes" label="备注"  rules={[{required: true, message: "备注必填"},{max:255, message: "备注长度不能超过255位"}]}>
                        <TextArea rows={2} style={{width:300}}/>
                    </Form.Item>
                </Form>
            </Modal>
            {/*启用的二次弹窗确认*/
            }
            <Modal
                cancelText="取消"
                okText="确认"
                title="" open={openEnable} onOk={() => {
                handleOperate();
            }} onCancel={() => {
                setOperate(0)
                setOpenEnable(false)
            }}>
                该操作用于启用,请确认是否继续
            </Modal>
            <Modal
                cancelText="取消"
                okText="确认"
                title="" open={openDisable} onOk={() => {
                handleOperate();
            }} onCancel={() => {
                setOperate(0)
                setOpendisable(false)
            }}>
                该操作用于禁用,请确认是否继续
            </Modal>
            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                    setRowData({} as TableDataType)
                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>

            <InsGrantForm
                refresh={() => {
                    //设置表单不展示
                    setOpenGrantForm(false);
                }}
                open={openGrantForm}
                closeOpen={() => {
                    setOpenGrantForm(false)
                    handleQuery()
                }}
                insId={idList[0]}
            ></InsGrantForm>

        </div>
    )
}


export default InstanceContent;